body {
  background: linear-gradient(
      0deg,
      rgba(78, 78, 80, 0.5),
      rgba(78, 78, 80, 0.5)
    ),
    url("./images/header.jpeg") no-repeat center center fixed;
  -webkit-background: cover;
  -moz-background: cover;
  -o-background: cover;
  background: cover;
  height: 100%;
}
